@import "./variables";

:root {
  color-scheme: light only;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  color: $white;
  width: 100%;
  height: 100%;
}

a {
  color: $blue;
  text-decoration: none;
}

input,
button,
select,
.split-button {
  border-radius: 6px;
  outline: none;
  padding: 5px;
  margin: 5px;
  font-size: inherit;
}

input:not([type="checkbox"]) {
  padding: 5px 8px;
}

input,
select {
  border: none;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);

  &[type="number"] {
    width: 50px;
  }
}

button {
  display: block;
  font-size: inherit;
  font-weight: bold;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.primary {
    background-color: $red;
    color: white;

    &:active {
      background-color: darken($red, 10);
    }
  }

  &.secondary {
    background-color: #ccc;

    &:active {
      background-color: darken(#ccc, 10);
    }
  }

  &.highlight {
    background-color: $yellow;
    color: #333;

    &:active {
      background-color: darken($yellow, 10);
    }
  }
}

.split-button {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  padding: 0;

  :first-child {
    flex-grow: 1;
  }

  button {
    margin: 0;
    border-radius: 0;

    &:not(:first-child) {
      border-left: 1px solid white;
    }
  }
}

#root {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ed1c24;
  background-image: url("../../../assets/45-degree-fabric-dark.png");
  background-size: 150px;
  width: 100%;
  height: 100%;
}

.homepage {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;

  &-title {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    span {
      font-size: 20px;
      font-weight: bold;
      padding-left: 4px;
    }
  }

  .uno-title {
    font-size: 80px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 20px;
    color: $yellow;
    -webkit-text-stroke: 2px white;
    text-shadow: black 0.05em 0.03em 0;
  }

  &-go-btn {
    color: white;
    font-weight: bold;
    text-decoration: none;
    line-height: 0;
  }

  &-navigator {
    width: 80%;
    max-width: 300px;
    font-size: 18px;
    margin-bottom: 60px;

    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
    }

    .label {
      font-size: 0.7em;
      margin-bottom: 3px;
    }

    .room-name {
      & > :not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      & > :not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .submit {
      margin-top: 10px;
      font-size: 0.8em;

      button {
        flex-grow: 1;
        height: 2em;
      }
    }

    .randomize-btn {
      @extend .secondary;
    }

    input,
    button {
      margin: 0;
    }

    input {
      flex-grow: 1;
    }
  }
}

.uno-game {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .table {
    position: relative;
    flex-grow: 1;
    z-index: 1;
  }

  .hand {
    position: relative;
    flex: 0 0 auto;
  }
}

.banner {
  min-height: 45px;
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  padding: 10px;
  color: $white;
  background-color: $red;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 45px;
  transition: padding 0.5s;

  &:empty {
    display: none;
  }

  .menu-container.open + & {
    padding-left: 295px;
  }
}
