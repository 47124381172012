@import "./variables";

.card-wheel {
  display: flex;
  justify-content: center;
  position: relative;
  height: 30vh;
  max-height: 150px;
  box-sizing: content-box;
  padding: 10px 0 5px;
  align-items: flex-end;
  background-color: rgba($black, 0.2);
  box-shadow: 0 0 3px inset rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s;

  .playable-card {
    min-width: 40px;
    transition: transform 0.3s;
    z-index: 0;
    margin: 0 2px;

    // &:hover {
    //   transform: translateY(-30%);
    // }

    &:first-child {
      margin-left: 15px;
    }

    &:last-child {
      min-width: auto;
      padding-right: 15px;
    }

    &.enabled {
      transform: translateY(-20px);
    }
  }

  .card {
    width: 20vh;
    max-width: 100px;
    z-index: 1;
  }

  .counter {
    position: absolute;
    font-weight: bold;
    user-select: none;
    line-height: 1em;
    font-size: 12px;
    bottom: 0;
    right: 0;
    padding: 5px 8px;
    background-color: rgba(0, 0, 0, 0.8);
    border-top-left-radius: 5px;
  }

  &-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    height: 30vh;
    max-height: 150px;
    box-sizing: content-box;
    padding: 150px 0 0;
  }
}

.card-pickup-enter .card {
  transform: translateY(-50%);
}

.card-pickup-enter-active .card {
  transform: translateY(0);
  transition: transform 3s cubic-bezier(0, 1, 0, 1);
}
