.fullscreen-toggle {
  svg {
    margin-right: 8px;
  }
}

@media (display-mode: standalone) {
  .fullscreen-toggle {
    display: none;
  }
}
