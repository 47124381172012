@import "./variables";

.player {
  opacity: 0.8;
  transition: opacity 0.5s;

  &-container {
    position: absolute;
    transform: translate(-50%, -50%);
    overflow: visible;
    max-width: 100px;
    transition:
      top 0.5s,
      left 0.5s;
    touch-action: none;

    .disconnected-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 48px;
    }

    .arrow {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 5px;
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 20px;
    }

    .turn-timer {
      position: absolute;
      bottom: 100%;
      left: 50%;
      padding-bottom: 5px;
      padding-left: 15px;
      font-size: 12px;
      font-weight: bold;
      line-height: 21.5px;
    }

    &.active {
      .arrow {
        opacity: 1;
      }
    }
  }

  &.active {
    opacity: 1;
  }

  &.inactive {
    opacity: 0.5;
  }

  &.disconnected {
    opacity: 0.4;
  }

  &-hand {
    height: 50px;
    max-width: 100px;
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .card-count {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      font-weight: bold;
    }

    .card-wrapper {
      position: relative;
      height: 100%;
      width: 10px;
      min-width: 0;
      overflow: visible;

      &:last-child {
        width: auto;
        min-width: auto;
      }
    }

    .card {
      width: 33.3333333333px;
    }
  }

  &-name {
    text-align: center;
    font-weight: bold;
    max-height: 120px;
    overflow: hidden;
    line-height: 1.1;
    text-shadow: black 0 0 2px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.large {
      font-size: 56px;
    }

    &.medium {
      font-size: 36px;
    }

    &.small {
      font-size: 24px;
    }
  }

  .ai-badge {
    display: inline-block;
    margin-right: 8px;
  }
}

.card-slide-enter {
  width: 0;
  transform: translateY(100%);
}

.card-slide-enter-active {
  width: 10px;
  transform: translateY(0);
  transition: all 300ms ease-out;
}
