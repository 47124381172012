@import "./variables.scss";

.stacks {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18vh;
  max-width: 125px;
  z-index: 200;
  user-select: none;

  @media only screen and (min-width: 600px) and (max-height: 560px) {
    width: 25vh;
  }
}

.up-stack,
.down-stack {
  display: block;
  position: relative;
  line-height: 0;

  .counter {
    position: absolute;
    font-weight: bold;
    user-select: none;
    line-height: 1em;
    font-size: 10px;
    bottom: -3px;
    right: -5px;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
  }

  .hint-text {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    text-shadow: black 0 0 2px;
  }

  .direction {
    opacity: 0.7;

    svg {
      position: absolute;
      transition: transform 0.5s;
      height: 62px;
      margin: 0 4px;

      &:nth-of-type(1) {
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
      }

      &:nth-of-type(2) {
        top: 50%;
        left: 100%;
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &.ccw {
      svg {
        &:nth-of-type(1) {
          transform: translateY(-50%) rotateX(-180deg);
        }

        &:nth-of-type(2) {
          transform: translateY(-50%) rotate(180deg) rotateX(180deg);
        }
      }
    }
  }

  .card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.up-stack {
  width: 100%;
  padding-top: 66.6666666666%;
  position: relative;

  .card {
    position: absolute;
    transform: rotate(90deg);
    transform-origin: 0 0;
    width: 66.666666666%;
    height: 150%;
    top: 0;
    left: 100%;
  }

  &.highlight .card {
    box-shadow: 0 0 5px 5px $yellow;
  }

  @media only screen and (min-width: 600px) and (max-height: 560px) {
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(0, -50%);
  }
}

.down-stack {
  width: 80%;
  padding-top: 120%;
  margin-bottom: 10px;

  @media only screen and (min-width: 600px) and (max-height: 560px) {
    margin-bottom: 0;
    transform: translateX(-65%);
  }
}

.place-card-enter {
  opacity: 0;
  z-index: 1;
}

.place-card-enter-active {
  transform: translate(0);
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.place-card-exit {
  z-index: -1;
}
