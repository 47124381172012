.toasts {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 5px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  overflow: hidden;

  .toast {
    overflow: visible;
    display: flex;
    align-items: flex-end;
    max-width: 300px;
    margin-top: 5px;
    z-index: 500;
  }

  .toast-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 15px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.3;
  }

  .toast-slide-enter {
    transform: translateX(100%);
  }

  .toast-slide-enter-active {
    transform: translateX(0);
    transition: transform 0.5s;
  }

  .toast-slide-exit {
    opacity: 1;
  }

  .toast-slide-exit-active {
    height: 0;
    opacity: 0;
    transition:
      height 0.5s,
      opacity 0.5s;
  }
}
