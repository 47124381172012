.color-chooser {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);

  font-size: 28px;
  font-style: italic;
  font-weight: bold;

  svg {
    min-height: 400px;
    height: 50%;
  }

  @media only screen and (min-width: 600px) and (max-height: 560px) {
    flex-flow: row nowrap;

    svg {
      height: 90%;
    }
  }
}

.color-chooser-color {
  fill: white;
  fill-opacity: 0;

  &:hover {
    fill-opacity: 0.4;
  }
}
